

import { ModelSelect, ModelListSelect  } from 'vue-search-select'
import 'vue-search-select/dist/VueSearchSelect.css'

// import UMUM from         '../../library/umum.js'
import UMUM from         '../../library/umum'
import DATA_MASTER from  '../../library/dataMaster'
import FETCHING from "../../library/fetching";



  export default {
    data () {
      return {

        form : {
          id : '',
          uraian : '',
        },

        page_first: 1,
        page_last: 0,
        page_limit : 10,
        cari_value: "",
        FETCHING : FETCHING,
        UMUM : UMUM,


        page: 1,
        mdl_add : false,
        mdl_edit : false,
        mdl_remove : false,

        cthSelect : '',

        list_data : [],
        list_menu : [],



      }
    },
    methods: {
      getView : function(){
        // this.$store.commit("shoWLoading");
        fetch(this.$store.state.url.URL_DM_KLP_USERS + "view", {
            method: "POST",
            headers: {
            "content-type": "application/json",
            authorization: "kikensbatara " + localStorage.token
            },
            body: JSON.stringify({
                data_ke: this.page_first,
                cari_value: this.cari_value,
                page_limit : this.page_limit,
            })
        })
            .then(res => res.json())
            .then(res_data => {
              console.log(res_data)
              this.list_data = res_data.data;
              this.page_last = res_data.jml_data;
        });
      },


      addData : function(number) {
        // this.form.createdAt = UMUM.NOW()
        fetch(this.$store.state.url.URL_DM_KLP_USERS + "addData", {
            method: "POST",
            headers: {
              "content-type": "application/json",
              authorization: "kikensbatara " + localStorage.token
            },
            body: JSON.stringify({
              form : this.form,
              list_menu : this.list_menu
            })
        }).then(res_data => {
            this.getView();
            this.Notify('Sukses Menambah Data', 'primary', 'check_circle_outline');
        });


      },

      editData : function(){
        fetch(this.$store.state.url.URL_DM_KLP_USERS + "editData", {
            method: "POST",
            headers: {
              "content-type": "application/json",
              authorization: "kikensbatara " + localStorage.token
            },
            body: JSON.stringify({
              form : this.form,
              list_menu : this.list_menu
            })
        }).then(res_data => {

            this.getView();
            this.Notify('Sukses Mengubah Data', 'primary', 'check_circle_outline');
        });
      },

      removeData : async function(data){

        await UMUM.notifDelete();
        fetch(this.$store.state.url.URL_DM_KLP_USERS + "removeData", {
            method: "POST",
            headers: {
              "content-type": "application/json",
              authorization: "kikensbatara " + localStorage.token
            },
            body: JSON.stringify(data)
        }).then(res_data => {
            this.getView();
            this.mdl_remove = false;
            this.Notify('Sukses Menghapus Data', 'primary', 'check_circle_outline');
        });

      },

      selectData : async function(data){
          this.form.id = data.id;
          this.form.uraian = data.uraian;
          this.list_menu = await this.FETCHING.postMasterMenu(data.id);

      },

      // ====================================== PAGINATE ====================================
        indexing : function(index){
            var idx = ((this.page_first-1)*this.page_limit)+index
            return idx
        },

        cari_data : function(){
            this.page_first = 1;
            this.getData();
        },


        funcAwaitAdd : async function(){
          // this.$store.commit('ubahState', {name : 'list_menu', list: await this.FETCHING.getMasterMenu()})
          // console.log(this.$store.state.list_menu)
          this.list_menu = await this.FETCHING.getMasterMenu();
        }


      // ====================================== PAGINATE ====================================





    },
    mounted () {
      this.getView();
      // this.funcAwait()


    },
  }
